export interface IGrandMasterKey {
  grandMasterKeyID: number;
  keyName: string | null;
  orderID: number;
  orderLockSystemID: number;
  numberOfKeys: number;
  colorID: number;
  engravedKey: boolean;
  keyText: string;
}

export default class GrandMasterKey implements IGrandMasterKey {
  constructor(
    public grandMasterKeyID: number = 0,
    public keyName: string | null = null,
    public orderID: number = 0,
    public orderLockSystemID: number = 0,
    public numberOfKeys: number = 0,
    public colorID: number = 0,
    public engravedKey: boolean = false,
    public keyText: string = ""
  ) {}

  /**
   *
   * @param input
   * @returns
   */
  static deserialize(input: Object): GrandMasterKey {
    return input as IGrandMasterKey;
  }
}
