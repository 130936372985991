import { useOktaAuth } from "@okta/okta-react";
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

/**
 * Okta will return authState.isAuthenticated as false if the user is not authorized. 
 * This should be called after Okta authentification, a false isAuthenticated 
 * means not authorized since we know a user is authenticated at this point.
 */
const Authorize = (): JSX.Element => {
  const { authState } = useOktaAuth();
  const navigate = useNavigate()
  
  useEffect(() => {
    if (authState && !authState.isAuthenticated){
      navigate('/error')
    }
    else {
      navigate('/')
    }
  })

  return <></>
}

export default Authorize;