import axios from "axios";
import { API_BASE_URL, getConfig } from "./apiBase";

/**
 * Gets the roles the user account is associated with.
 * @param authToken 
 * @returns An array of the roles the user has.
 */
export const login = async (authToken: string): Promise<string[]> => {
  const response = await axios.get<string[]>(`${API_BASE_URL}/Identity`, getConfig(authToken))     
  return response.data;
}