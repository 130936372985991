import style from '../../styles/Buttons.module.css'

export const ButtonTypes = {
  Search: 1,
  Clear: 2,
}

export const Button = ({buttonText, buttonType, onClick}) => {
  let buttonStyle;
  switch (buttonType)
  {
    case ButtonTypes.Search:
      buttonStyle = style.searchButton
      break
    case ButtonTypes.Clear:
      buttonStyle = style.clearButton
      break
    default:
      buttonStyle = style.searchButton
      break
  }

  return (
    <button className={buttonStyle} onClick={onClick}>{buttonText}</button>
  )
}