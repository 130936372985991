import style from '../../styles/TextInput.module.css'

export interface ITextInputProps {
  value: string;
  labelText: string;
  placeholderText?: string;
  readOnly?: boolean;
  onChange?: (newValue: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

/**
 * A single line text input.
 * @returns
 */
export const TextInput = (props: ITextInputProps) => {
  return (
    <div className={style.inputFieldContainer}>
      <span className={style.fieldLabel}>{props.labelText}</span>
      <input 
        className={style.fieldInput}
        placeholder={props.placeholderText ?? props.labelText}
        value={props.value}
        disabled={props.readOnly}
        onChange={(e) => {
          if (props.onChange) {
            props.onChange(e.target.value)
          }
        }}
        onKeyDown={(e) => {
          if (props.onKeyDown) {
            props.onKeyDown(e)
          }
        }}
      />
    </div>
  )
}

export interface ITextAreaInputProps {
  value: string;
  labelText: string;
  placeholderText?: string;
  readOnly?: boolean;
  onChange?: (newValue: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  style?: React.CSSProperties | undefined;
}

export const MultilineTextInput = (props: ITextAreaInputProps): JSX.Element => {
  return (
    <div className={style.inputFieldContainer}>
      <span className={style.fieldLabel}>{props.labelText}</span>
      <textarea 
        className={style.fieldInput}
        placeholder={props.placeholderText ?? props.labelText}
        value={props.value}
        disabled={props.readOnly}
        rows={10}
        style={props.style}
        onChange={(e) => {
          if (props.onChange) {
            props.onChange(e.target.value)
          }
        }}
        onKeyDown={(e) => {
          if (props.onKeyDown) {
            props.onKeyDown(e)
          }
        }}
      />
    </div>
  )
}