import { getAppInsights } from './telemetryService'
import { IEventTelemetry, SeverityLevel } from "@microsoft/applicationinsights-common"

/**
 * Writes informational log messages
 * @param message 
 */
const info = (message: string): void => {
  log(message, SeverityLevel.Information)
}

/**
 * Writes warning log messages
 * @param message 
 */
const warning = (message: string): void => {
  log(message, SeverityLevel.Warning)
}

/**
 * Writes error log messages
 * @param error 
 */
const error = (error: string) => {
  log(error, SeverityLevel.Error)
}

/**
 * Write event logs
 * @param name 
 * @param properties 
 */
const event = (name: string, properties: any) => {
  let obj: IEventTelemetry = {
      name,
      properties
  }

  let insights = getAppInsights()
  if (insights) {
      insights.trackEvent(obj)
  }
}

/**
 * 
 * @param message 
 * @param severity 
 */
const log = (message: string, severity: SeverityLevel) => {  
  let obj = {
      message: message,
      severityLevel: severity
  }

  let insights = getAppInsights()
  if (insights) {
      if (severity === SeverityLevel.Error || severity === SeverityLevel.Critical) {
        insights.trackException(obj)
      } else {
        insights.trackTrace(obj)
      }
  }
}

const logger = {info, warning, error, event}
export default logger