/**
 * Any keys that originally have an uppercase letter will be converted to an 
 * all lowercase key with the same value.
 */
export default class URLSearchParamsLowerCaseKey extends URLSearchParams {
  constructor(
    url:
      | string
      | URLSearchParams
      | string[][]
      | Record<string, string>
      | undefined
  ) {
    super("");
    const params = new URLSearchParams(url);
    for (const [name, value] of params) {
      this.append(name.toLowerCase(), value);
    }
  }
}
