import React, { useState, createContext, useEffect } from "react";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {

  const BEARER_TOKEN_KEY = "bearerToken"
  const ORDER_REVIEW_PASSCODE_KEY = "orderReviewPassCode"
  const GUEST_AUTHENTICATION_KEY = "guestAuthenticationKey"
  const SYSTEM_READONLY_MODE_KEY = "systemReadOnlyMode"
  const ORDER_REQUEST_URL_PARAM = "orderRequestURLParam"

  const [bearerToken, setBearerToken] = useState(
    localStorage.getItem(BEARER_TOKEN_KEY) || ''
  );
  const [reviewPassCode, setReviewPassCode] = useState(
    localStorage.getItem(ORDER_REVIEW_PASSCODE_KEY) || ''
  );
  const [guestAuthenticationKey, setGuestAuthenticationKey] = useState(
    localStorage.getItem(GUEST_AUTHENTICATION_KEY) || ''
  );
  const [systemReadOnlyMode,setSystemReadOnlyMode] = useState(
    localStorage.getItem(SYSTEM_READONLY_MODE_KEY) === 'true' || false
  )
  const [requestURLParameters,setRequestURLParameters] = useState(
    localStorage.getItem(ORDER_REQUEST_URL_PARAM) || ''
  )

  const [errorContext, setErrorContext] = useState(null)

  useEffect(() => {
    localStorage.setItem(BEARER_TOKEN_KEY, bearerToken);
    localStorage.setItem(ORDER_REVIEW_PASSCODE_KEY, reviewPassCode);
    localStorage.setItem(GUEST_AUTHENTICATION_KEY, guestAuthenticationKey);
    localStorage.setItem(SYSTEM_READONLY_MODE_KEY, systemReadOnlyMode.toString());
    localStorage.setItem(ORDER_REQUEST_URL_PARAM, requestURLParameters.toString());
  }, [bearerToken,reviewPassCode,guestAuthenticationKey,systemReadOnlyMode,requestURLParameters]);

  return (
    <AuthContext.Provider
      displayName='AuthContext'
      value={{
        bearerToken,
        setBearerToken,
        reviewPassCode, setReviewPassCode,
        guestAuthenticationKey, setGuestAuthenticationKey,
        systemReadOnlyMode,setSystemReadOnlyMode,
        requestURLParameters,setRequestURLParameters,
        errorContext, setErrorContext
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
