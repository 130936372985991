import HelpIcon from "@mui/icons-material/Help";
import { useContext, useEffect, useState } from "react";
import style from "../../styles/Help.module.css";
import { useOktaAuth } from "@okta/okta-react";
import Grid from "@mui/material/Grid";
import { MultilineTextInput, TextInput } from ".././inputs/TextInput";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import { ButtonTypes } from "./Button";
import { Button as SafeKeyButton } from "./Button";
import { postHelpRequest } from "../../providers/helpApi";
import HelpRequest from "../../models/HelpRequest";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { LockOrderContext } from "../../context/LockOrderContext";
import { HelpOutline } from "@mui/icons-material";


const confluenceUrl: string | undefined = process.env.REACT_APP_CONFLUENCE;

const HelpButton = (): JSX.Element => {
  const { ccid, sapOrderNumber, designID } = useContext(LockOrderContext);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [summary, setSummary] = useState("");
  const [description, setDescription] = useState("");
  const location = useLocation();

  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    const getOktaUser = async () => {
      if (authState?.isAuthenticated) {
        const user = await oktaAuth.getUser();
        setUserEmail(user.email || "");
      }
    };

    getOktaUser();
  }, [authState?.isAuthenticated]);

  const toggleDialog = (): void => {
    setIsDialogOpen(!isDialogOpen);
    setSummary("");
    setDescription("");
  };

  /**
   *
   * @returns
   */
  const submitHelpRequest = async (): Promise<void> => {
    // required fields
    if (summary.trim() === "" || description.trim() === "") {
      return;
    }

    toast.info("Creating Jira ticket...");
    toggleDialog();
    try {
      var helpRequest = new HelpRequest(
        userEmail,
        summary,
        description,
        ccid,
        sapOrderNumber,
        designID.toString(),
        location.pathname
      );
      var helpResponse = await postHelpRequest(
        helpRequest,
        authState?.accessToken?.accessToken || ""
      );
      toast.info(`Jira ticket ${helpResponse.key} created`);
    }
    catch (e) {
      toast.error(`Error Creating Jira Ticket`);
    }
  };

  const onFaqClick = () => {
    window.open(confluenceUrl, "_safekeyfaq");
  }

  return (
    <>
      <Dialog open={isDialogOpen} onClose={toggleDialog}>
        <DialogTitle className={style.dialogTitle}>
          Request Assistance
          <Tooltip hidden={confluenceUrl == null} title="FAQ">
            <HelpOutline
              color="primary"
              className={style.faq}
              onClick={onFaqClick}
            />
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1} direction="column">
            <Grid item>
              <TextInput readOnly labelText={`User`} value={userEmail ?? ""} />
            </Grid>
            <Grid item>
              <TextInput
                labelText={"Summary"}
                value={summary}
                onChange={(newValue) => setSummary(newValue)}
              />
            </Grid>
            <Grid item>
              <MultilineTextInput
                labelText="Description"
                value={description}
                onChange={(newValue) => setDescription(newValue)}
                style={{ height: "230px", resize: "vertical" }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <SafeKeyButton
            buttonText={`Cancel`}
            buttonType={ButtonTypes.Clear}
            onClick={toggleDialog}
          />
          <SafeKeyButton
            buttonText={`Submit`}
            buttonType={ButtonTypes.Search}
            onClick={submitHelpRequest}
          />
        </DialogActions>
      </Dialog>
      <Button
        hidden={!authState?.isAuthenticated}
        onClick={toggleDialog}
        title={`Ask for help`}
      >
        <HelpIcon
          color="primary"
          fontSize="large"
          className={style.bottomright}
        />
      </Button>
    </>
  );
};

export default HelpButton;
