import axios from "axios"
import ChartingOption from "../models/LookUp/ChartingOption"
import Color from "../models/LookUp/Color"
import EngraveFontOption from "../models/LookUp/EngraveFontOption"
import EngravingOption from "../models/LookUp/EngravingOption"
import KeyCodeBucketType from "../models/LookUp/KeyCodeBucketType"
import KeyOption from "../models/LookUp/KeyOption"
import LockMaterial from "../models/LookUp/LockMaterial"
import LockMaterialDependency from "../models/LookUp/LockMaterialDependency"
import ShackleType from "../models/LookUp/ShackleType"
import { getAxiosRequestConfig } from "./apiBase"

/**
 * 
 * @param bearerToken 
 * @returns 
 */
export const lookUpColors = async (bearerToken: string): Promise<Color[]> => {
  const response = await axios.get<Color[]>(`LookUp/colors`, getAxiosRequestConfig(bearerToken))
  return response.data
}

/**
 * @deprecated
 */
export const lookUpEngravingOptions = async (bearerToken: string): Promise<EngravingOption[]> => {
  const response = await axios.get<EngravingOption[]>(`LookUp/engravingOptions`, getAxiosRequestConfig(bearerToken))
  return response.data
}

export const lookUpKeyOptions = async (bearerToken: string): Promise<KeyOption[]> => {
  const response = await axios.get<KeyOption[]>(`LookUp/keyOptions`, getAxiosRequestConfig(bearerToken))
  return response.data
}

/**
 * @deprecated
 */
export const lookUpChartingOptions = async (bearerToken: string): Promise<ChartingOption[]> => {
  const response = await axios.get<ChartingOption[]>(`LookUp/chartingOptions`, getAxiosRequestConfig(bearerToken))
  return response.data
}

export const lookUpLockMaterials = async (bearerToken: string): Promise<LockMaterial[]> => {
  const response = await axios.get<LockMaterial[]>(`LookUp/lockMaterials`, getAxiosRequestConfig(bearerToken))
  return response.data
}

export const lookUpShackleTypes = async (bearerToken: string): Promise<ShackleType[]> => {
  const response = await axios.get<ShackleType[]>(`LookUp/shackleTypes`, getAxiosRequestConfig(bearerToken))
  return response.data
}

export const lookUpLockMaterialDependencies = async (bearerToken: string): Promise<LockMaterialDependency[]> => {
  const response = await axios.get<LockMaterialDependency[]>(`LookUp/lockmaterialdependencies`, getAxiosRequestConfig(bearerToken))
  return response.data
}

export const lookUpEngraveFontOptions = async (bearerToken: string): Promise<EngraveFontOption[]> => {
  const response = await axios.get<EngraveFontOption[]>(`LookUp/engravefontoptions`, getAxiosRequestConfig(bearerToken))
  return response.data
}

export const lookUpKeyCodeBucketTypes = async (bearerToken: string): Promise<KeyCodeBucketType[]> => {
  const response = await axios.get<KeyCodeBucketType[]>(`LookUp/keycodebuckettype`, getAxiosRequestConfig(bearerToken))
  return response.data
}