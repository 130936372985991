import React, { useState, createContext } from "react";

export const SettingsContext = createContext();

export const SettingsContextProvider = ({ children }) => {

  // const [settings, setSettings] = useState(
  //   JSON.parse(localStorage.getItem(SETTINGS_CONTEXT_KEY)) || {id:0}
  // );
  const [settings, setSettings] = useState(
    {debugMode: process.env.REACT_APP_DEBUG_MODE ? process.env.REACT_APP_DEBUG_MODE : false}
  );

  // useEffect(() => {
  //   localStorage.setItem(SETTINGS_CONTEXT_KEY, JSON.stringify(settings));
  // }, [settings]);

  return (
    <SettingsContext.Provider displayName='SettingsContext' value={{settings, setSettings}}>
      {children}
    </SettingsContext.Provider>
  );
};
