import SetSummaryModel from "./SetSummaryModel";
import LockSet_Set_Lock from "./LockSet_Set_Lock";

export interface ILockSet {
  grandMasterKeyID?: number;
  masterKeyID?: number;
  lockSetID?: number;
  orderID?: number;
  orderLockSystemID?: number;
  numberOfKeysPerLock?: number;
  bodyMaterialID?: number;
  colorID?: number;
  shackleMaterialID?: number;
  engravedLock?: boolean;
  engravedKey?: boolean;
  fontSize?: string;
  frontText1?: string;
  frontText2?: string;
  frontText3?: string;
  backText1?: string;
  backText2?: string;
  backText3?: string;
  leftText1?: string;
  leftText2?: string;
  rightText1?: string;
  rightText2?: string;
  keyText?: string;
  pricePer?: number;
  charted?: boolean;
  logoFront?: string;
  logoBack?: string;
  sku?: string;
  lockSetName?: string;
  numberOfKeysPerSet?: number;
  keyOption?: number;
  numberOfLocks?: number;
  numberOfSets?: number;
  numberOfLocksPerSet?: number;
  locks: Array<LockSet_Set_Lock>;
  setSummary: Array<SetSummaryModel>;
  addOnLocksCount?: number;
  reorderLocksCount?: number;
  qtyModifiedSetsCount?: number; // number of sets that have an add on lock added (not reorder), so qty differs
}

export default class LockSet implements ILockSet {
  constructor(
    public grandMasterKeyID?: number,
    public masterKeyID?: number,
    public lockSetID?: number,
    public orderID?: number,
    public orderLockSystemID?: number,
    public numberOfKeysPerLock?: number,
    public bodyMaterialID?: number,
    public colorID?: number,
    public shackleMaterialID?: number,
    public engravedLock?: boolean,
    public engravedKey?: boolean,
    public fontSize?: string,
    public frontText1?: string,
    public frontText2?: string,
    public frontText3?: string,
    public backText1?: string,
    public backText2?: string,
    public backText3?: string,
    public leftText1?: string,
    public leftText2?: string,
    public rightText1?: string,
    public rightText2?: string,
    public keyText?: string,
    public pricePer?: number,
    public charted?: boolean,
    public logoFront?: string,
    public logoBack?: string,
    public sku?: string,
    public lockSetName?: string,
    public numberOfKeysPerSet?: number,
    public keyOption?: number,
    public numberOfLocks?: number,
    public numberOfSets?: number,
    public numberOfLocksPerSet?: number,
    public locks: Array<LockSet_Set_Lock> = [],
    public setSummary: Array<SetSummaryModel> = [],
    public addOnLocksCount?: number,
    public reorderLocksCount?: number,
    public qtyModifiedSetsCount?: number // number of sets that have an add on lock added (not reorder), so qty differs
  ) {}

  /**
   *
   * @param input
   * @returns
   */
  static deserialize(input: Object): LockSet {
    return input as ILockSet;
  }
}
