import axios from "axios";
import HelpRequest from "../models/HelpRequest";
import HelpResponse from "../models/HelpResponse";
import { API_BASE_URL, getAxiosRequestConfig } from "./apiBase";

/**
 * Creates a ticket in the Jira board for the IT App Dev team.
 * @param helpRequest
 * @param authToken
 * @returns
 */
export const postHelpRequest = async (
  helpRequest: HelpRequest,
  authToken: string
): Promise<HelpResponse> => {
  const response = await axios.post<HelpResponse>(
    `${API_BASE_URL}/help`,
    helpRequest,
    getAxiosRequestConfig(authToken)
  );
  return response.data;
};
