import React, { useState, createContext, useEffect } from "react";
import { toast } from "react-toastify";
import { lookUpColors, lookUpEngraveFontOptions, lookUpKeyOptions, lookUpLockMaterialDependencies, lookUpLockMaterials, lookUpShackleTypes, lookUpKeyCodeBucketTypes } from "../providers/lookUpApi";
import { useOktaAuth } from "@okta/okta-react";
import Color from "../models/LookUp/Color";
import KeyOption from "../models/LookUp/KeyOption";
import LockMaterial from "../models/LookUp/LockMaterial";
import ShackleType from "../models/LookUp/ShackleType";
import EngraveFontOption from "../models/LookUp/EngraveFontOption";
import LockMaterialDependency from "../models/LookUp/LockMaterialDependency";
import { LookupContextType } from "../types/lookupContextType";
import KeyCodeBucketType from "../models/LookUp/KeyCodeBucketType";

export const LookupContext = createContext<LookupContextType>({} as LookupContextType)

interface ILookupContextProviderProps extends React.PropsWithChildren {
}

export const LookupContextProvider = (props: ILookupContextProviderProps) => {
  const { authState } = useOktaAuth();

  const [lockColorOptions, setLockColorOptions] = useState<Color[]>([])
  const [keyOptions, setKeyOptions] = useState<KeyOption[]>([])
  const [lockMaterials, setLockMaterials] = useState<LockMaterial[]>([])
  const [shackleTypes, setShackleTypes] = useState<ShackleType[]>([])
  const [engravingFontOptions, setEngravingFontOptions] = useState<EngraveFontOption[]>([])
  const [lockMaterialDependencies, setLockMaterialDependencies] = useState<LockMaterialDependency[]>([])
  const [keyCodeBucketTypes, setKeyCodeBucketTypes] = useState<KeyCodeBucketType[]>([])

  useEffect(() => {
    const getLookups = async () => {
      if(!authState?.isAuthenticated || !authState.accessToken?.accessToken){
        return
      }

      let bearerToken = authState.accessToken?.accessToken
      try {
        const colorsPromise = lookUpColors(bearerToken)
        const keyOptionsPromise = lookUpKeyOptions(bearerToken)
        const bodyMaterialPromise = lookUpLockMaterials(bearerToken)
        const shackleTypePromise = lookUpShackleTypes(bearerToken)
        const engraveFontOptionsPromise = lookUpEngraveFontOptions(bearerToken)
        const dependenciesPromise = lookUpLockMaterialDependencies(bearerToken)
        const keyCodeBucketTypePromise = lookUpKeyCodeBucketTypes(bearerToken)
        const [colors, keyOptions, bodyMaterials, shackleTypes, engraveFontOptions, dependenciesList, keyCodeBucketTypeList] = await Promise.all([
          colorsPromise,
          keyOptionsPromise,
          bodyMaterialPromise,
          shackleTypePromise,
          engraveFontOptionsPromise,
          dependenciesPromise,
          keyCodeBucketTypePromise
        ])
        setLockColorOptions(colors)
        setKeyOptions(keyOptions)
        setLockMaterials(bodyMaterials)
        setShackleTypes(shackleTypes)
        setEngravingFontOptions(engraveFontOptions)  
        setLockMaterialDependencies(dependenciesList)
        setKeyCodeBucketTypes(keyCodeBucketTypeList)
      }
      catch (e) {
        toast.error("Error Fetching Lookup Options")
      }
    }

    getLookups()

  }, [authState])

  return (
    <LookupContext.Provider
      value={{
        lockColorOptions, setLockColorOptions,
        keyOptions, setKeyOptions,
        lockMaterials, setLockMaterials,
        shackleTypes, setShackleTypes,
        engravingFontOptions, setEngravingFontOptions,
        lockMaterialDependencies, setLockMaterialDependencies,
        keyCodeBucketTypes, setKeyCodeBucketTypes
      }}
    >
      {props.children}
    </LookupContext.Provider>
  );
};
