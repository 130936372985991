import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom'
import { LockOrderContextProvider } from './context/LockOrderContext'
import { LockOrderContext } from './context/LockOrderContext'
import { SettingsContextProvider } from './context/SettingsContext'
import { AuthContextProvider } from './context/AuthContext'
import { AuthContext } from './context/AuthContext'
import { SettingsContext } from './context/SettingsContext'
import { useState, useEffect, useContext, Suspense, lazy } from 'react'
import { Security, LoginCallback } from "@okta/okta-react"
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js"
import Authorize from './components/Authorize'
import { ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"
import HelpButton from './components/buttons/HelpButton'
import URLSearchParamsLowerCaseKey from './models/URLSearchParamsLowerCaseKey'
import logger from './providers/logger'
import { login } from './providers/identity'
import { LookupContextProvider } from './context/LookupContext'

const HomePage = lazy(() => import('./pages/HomePage'))
const KeySystemPage = lazy(() => import('./pages/KeySystemPage'))
const CustomerSearchPage = lazy(() => import('./pages/CustomerSearchPage'))
const SystemStartPage = lazy(() => import('./pages/SystemStartPage'))
const SetPage = lazy(() => import('./pages/SetPage'))
const ChartingFileSummaryPage = lazy(() => import('./pages/ChartingFileSummaryPage'))
const SubmitSystemPage = lazy(() => import('./pages/SubmitSystemPage'))
const ErrorPage = lazy(() => import('./pages/ErrorPage'))
const EditEngravingPage = lazy(() => import('./pages/EditEngravingPage'))
const ReorderLocksPage = lazy(() => import('./pages/ReorderLocksPage'))
const SubmitReviewPage = lazy(() => import('./components/KeySystemSubmitView'))

const issuer = process.env.REACT_APP_OKTA_ISSUER;
const redirect = process.env.REACT_APP_OKTA_APP_REDIRECT_URL;

const oktaAuth = new OktaAuth({
  issuer: issuer,
  clientId: window.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + redirect
});

const App = () => {
  logger.info('Starting SafeKey UI')
  
  const lockOrderContext = useContext(LockOrderContext)
  const { setOrderID, setCcid, setSapOrderNumber, setSapLineNumber, setOrderPONumber,
    setLoadByLineNumber, setDesignID, setLoadByDesignID, systemState, orderLockSystemID, setOrderCurrency } = lockOrderContext
  const { setReviewPassCode, setSystemReadOnlyMode, setRequestURLParameters, setErrorContext, bearerToken } = useContext(AuthContext)
  const { settings } = useContext(SettingsContext)

  const [isSummaryClicked, setIsSummaryClicked] = useState(false)

  const navigate = useNavigate()

  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true })
  };

  const [loadAsGuest, setLoadAsGuest] = useState(false);
  const [loadAsUser, setLoadAsUser] = useState(false);
  const [loadDefault, setLoadDefault] = useState(false);

  useEffect(() => {
    const onLogin = async () => {
      await login(bearerToken)
    }
    if (bearerToken) {
      onLogin();
    }
  }, [bearerToken])

  useEffect(() => {
    console.log(`orderLockSystemID changed, ${orderLockSystemID}`)
  }, [orderLockSystemID])

  useEffect(() => {
    console.log(`systemState changed, ${JSON.stringify(systemState)}`)
  }, [systemState])

  useEffect(() => {
    //console.log('trigger effect, load url params')

    const queryString = window.location.search;
    let urlParams = new URLSearchParamsLowerCaseKey(queryString);
    let newCcid = urlParams.get('ccid')
    let newSapOrderNumber = urlParams.get('sapordernumber') ?? urlParams.get('orderid')
    let newReviewPassCode = urlParams.get('review')
    let newLineNumber = urlParams.get('linenumber') ?? urlParams.get('saplinenumber')
    let newDesignID = urlParams.get('designid')?.toLowerCase()
    let currency = urlParams.get('currency')

    if (newDesignID === ''){
      newDesignID = 'new'
    }
    let newOrderID = urlParams.get('id')
    let newPONumber = urlParams.get('po') ?? urlParams.get('ponumber')

    if (newCcid || newSapOrderNumber || newDesignID || newReviewPassCode || newLineNumber || newOrderID || newPONumber || currency) {
      setRequestURLParameters(queryString)
    } else if (window.location.pathname === '/') {
      // If there's no url params and the pathname is empty, default to customer search
      navigate('/customer-search')
      return
    }

    if (newReviewPassCode && newOrderID) {
      //parameters for customer view
      if (newLineNumber) {
        setLoadByLineNumber(true);
        setSapLineNumber(newLineNumber.toString())
      } else {
        setLoadByLineNumber(false);
      }

      if (newDesignID) {
        setDesignID(newDesignID)
        setLoadByDesignID(!isNaN(parseInt(newDesignID)))
      } else {
        setDesignID('')
        setLoadByDesignID(false)
      }

      setReviewPassCode(newReviewPassCode.toString())
      setOrderID(newOrderID.toString())

      console.log('*******run setOrderCurrency? currency for querystring: ', currency, '    App.tsx 1')
      if (currency){
        console.log('******* setOrderCurrency(', currency,')  App.tsx 1')
        setOrderCurrency(currency)
      }

      setLoadAsGuest(true)
      setSystemReadOnlyMode(true)
    } else if (newSapOrderNumber && newCcid) {
      //minimum parameters for loading a system as a user
      if (newLineNumber) {
        setSapLineNumber(newLineNumber.toString())
        setLoadByLineNumber(true);
      } else {
        setLoadByLineNumber(false);
      }

      if (newDesignID) {
        setDesignID(newDesignID)
        setLoadByDesignID(!isNaN(parseInt(newDesignID)))
        if (newPONumber) {
          setOrderPONumber(newPONumber)
        }
      } else {
        setDesignID('')
        setLoadByDesignID(false)
        setOrderPONumber('')
      }

      setCcid(newCcid.toString().padStart(10,'0'))
      setSapOrderNumber(newSapOrderNumber.toString().padStart(10,'0'))

      console.log('*******run setOrderCurrency? currency for querystring: ', currency, '    App.tsx 2')
      if (currency){
        console.log('******* setOrderCurrency(', currency,') App.tsx 2')
        setOrderCurrency(currency)
      }

      setLoadAsUser(true)
      setSystemReadOnlyMode(false)
    } else if (newCcid) {
      setCcid(newCcid.toString().padStart(10, '0'))

      setSapLineNumber('')
      setLoadByLineNumber(false);
      setDesignID('')
      setLoadByDesignID(false)
      setSapOrderNumber('')
      setOrderCurrency('')
      console.log('******* setOrderCurrency(clear value))   App.tsx 3')
      setLoadAsUser(true)
      setSystemReadOnlyMode(false)
    } else if (newOrderID && newSapOrderNumber) {
      //for viewing from customer link
      setOrderID(newSapOrderNumber.toString())
      setSapLineNumber('')
      setLoadByLineNumber(false);
      setDesignID('')
      setLoadByDesignID(false)
      setCcid('')
      setOrderCurrency('')
      console.log('******* setOrderCurrency(clear value)   App.tsx 4')
      setReviewPassCode(newOrderID.toString())
      setLoadAsGuest(true)
      setSystemReadOnlyMode(true)
    } else {
      console.log('******* setOrderCurrency not reached App.tsx 5')
      setLoadDefault(true);
    }
  }, [])

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} >
      <LookupContextProvider>
        <div className="App">
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<HomePage
                loadAsGuest={loadAsGuest} setLoadAsGuest={setLoadAsGuest}
                loadAsUser={loadAsUser} setLoadAsUser={setLoadAsUser}
                loadDefault={loadDefault} setLoadDefault={setLoadDefault} />}
              />
              <Route path="/login/callback"
                element={
                  <LoginCallback errorComponent={() => {
                    //localStorage.clear()
                    try {
                      localStorage.removeItem("bearerToken")
                      localStorage.removeItem("okta-token-storage")
                    } catch (e) { }
                    navigate('/authorize')
                    return null
                  }}
                  />} />
              {/* <Route exact path="/system-selection" element={<KeySystemSelectionPage />} /> */}
              <Route path="/authorize" element={<Authorize />} />
              <Route path="/customer-search" element={<CustomerSearchPage />} />
              <Route path="/system-start" element={<SystemStartPage />} />
              <Route path="/key-system" element={<KeySystemPage
                isSummaryClicked={isSummaryClicked} setIsSummaryClicked={setIsSummaryClicked}
              />} />
              <Route path="/set" element={<SetPage
                isSummaryClicked={isSummaryClicked} setIsSummaryClicked={setIsSummaryClicked}
              />} />
              <Route path="/charting-file" element={<ChartingFileSummaryPage />} />
              <Route path="/submit-system" element={<SubmitSystemPage />} />
              <Route path="/error" element={<ErrorPage />} />
              <Route path="/edit-engraving" element={<EditEngravingPage />} />
              <Route path='/reorder-locks' element={<ReorderLocksPage />} />
              <Route path='/submit-review' element={<SubmitReviewPage />} />
              <Route path='/' element={<ReorderLocksPage />} />
            </Routes>
          </Suspense>
          {settings.debugMode && <label style={{ position: 'fixed', left: '.5em', bottom: '.5em', color: 'orange' }}>{JSON.stringify(systemState)}</label>}
          <HelpButton/>
        </div>
      </LookupContextProvider>
    </Security>
  )
}

const AppWrapper = () => {
  return (
      <BrowserRouter>
        <SettingsContextProvider>
          <LockOrderContextProvider>
            <AuthContextProvider>            
            <ToastContainer
              autoClose={3000}
              hideProgressBar={true}
            />  
              <App />
            </AuthContextProvider>
          </LockOrderContextProvider>
        </SettingsContextProvider>
      </BrowserRouter>
  )
}

export default AppWrapper