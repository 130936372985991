import React, { useState, createContext, useEffect } from "react";
import GrandMasterKey from '../models/GrandMasterKey'
import MasterKey from '../models/MasterKey'
import LockSet from '../models/LockSet'

const LOCK_ORDER_KEY = "lockOrder";
const MASTER_KEY_COUNTER_KEY = "masterKeyCounter";
const LOCK_SET_COUNTER_KEY = "lockSetCounter";
const ORDER_ID_KEY = "orderID";
const ORDER_LOCK_SYSTEM_ID_KEY = "orderLockSystemID";
const ORDER_LOCK_SYSTEM_STATUS_KEY = "orderLockSystemStatus"
const SYSTEM_BUCKET_TYPE_KEY = "systemBucketType"
const LOCK_SYSTEM_ID_KEY = "lockSystemID"
const CCID_KEY = "ccid";
const CUSTOMER_KEY = "customerModel"
const SAP_ORDER_NUMBER_KEY = "sapOrderNumber";
const SAP_LINE_NUMBER_KEY = "sapLineNumber";
const NO_KEY_LOCK_SETS_KEY = "noKeyLockSets"
//const LOCK_SYSTEMS_KEY = "lockSystems"
const ORDER_LOCK_SYSTEMS_KEY = "orderLockSystems"
const ORDER_LOADBY_LINENUMBER = "loadByLineNumber"
const DESIGN_ID_KEY = "designID"
const ORDER_LOADBY_DESIGNID = "loadByDesignID"
const ORDER_PO_NUMBER_KEY = 'orderPONumber'
const IS_ADD_TO_SYSTEM = 'isAddToSystem'
const ORDER_CURRENCY_KEY = 'orderDisplayCurrency'

export const LockOrderContext = createContext();

const initialLockOrderState =
{
  name: 'GRAND MASTER KEY',
  complete: false,
  type: 'Grand Master Key',
  item: new GrandMasterKey(),
  children: [
    {
      name: 'MASTER KEY 1',
      complete: false,
      type:'Master Key',
      item: new MasterKey(),
      children: [
        {
          name: 'LOCK SET 1',
          complete: false,
          type: 'Lock Set',
          item: new LockSet(),
        },
      ],
    },
  ],
}

const getLocalStorageJSON = (key) => {
  try {
    let obj = JSON.parse(localStorage.getItem(key))
    return obj;
  } catch (err){
    return undefined;
  }
}

export const LockOrderContextProvider = ({ children }) => {
  const [lockOrder, setLockOrder] = useState(
    getLocalStorageJSON(LOCK_ORDER_KEY) || initialLockOrderState
  );
  const [masterKeyCounter, setMasterKeyCounter] = useState(
    parseInt(localStorage.getItem(MASTER_KEY_COUNTER_KEY)) || 0
  );
  const [lockSetCounter, setLockSetCounter] = useState(
    parseInt(localStorage.getItem(LOCK_SET_COUNTER_KEY)) || 2
  );
  const [orderID, setOrderID] = useState(
    parseInt(localStorage.getItem(ORDER_ID_KEY)) || '' //'null'
  );
  const [orderLockSystemID, setOrderLockSystemID] = useState(
    parseInt(localStorage.getItem(ORDER_LOCK_SYSTEM_ID_KEY)) || '' //null
  );
  const [orderLockSystemStatus, setOrderLockSystemStatus] = useState(
    parseInt(localStorage.getItem(ORDER_LOCK_SYSTEM_STATUS_KEY)) || '' //null
  );
  const [systemBucketType, setSystemBucketType] = useState(
    parseInt(localStorage.getItem(SYSTEM_BUCKET_TYPE_KEY)) || '' //null
  );
  const [lockSystemID, setLockSystemID] = useState(
    parseInt(localStorage.getItem(LOCK_SYSTEM_ID_KEY)) || '' //null
  );
  const [ccid, setCcid] = useState(
    localStorage.getItem(CCID_KEY) || '' //null
  );
  const [customer, setCustomer] = useState(
    getLocalStorageJSON(CUSTOMER_KEY) || {id:0}
  );
  const [sapOrderNumber, setSapOrderNumber] = useState(
    localStorage.getItem(SAP_ORDER_NUMBER_KEY) || ''
  );
  const [orderPONumber, setOrderPONumber] = useState(
    localStorage.getItem(ORDER_PO_NUMBER_KEY) || ''
  );
  const [sapLineNumber, setSapLineNumber] = useState(
    localStorage.getItem(SAP_LINE_NUMBER_KEY) || ''
  );
  const [noKeyLockSets, setNoKeyLockSets] = useState(
    getLocalStorageJSON(NO_KEY_LOCK_SETS_KEY) || []
  );
  // const [lockSystems, setLockSystems] = useState(
  //   getLocalStorageJSON(LOCK_SYSTEMS_KEY) || []
  // );
  const [orderLockSystems, setOrderLockSystems] = useState(
    getLocalStorageJSON(ORDER_LOCK_SYSTEMS_KEY) || []
  );
  const [loadByLineNumber,setLoadByLineNumber] = useState(
    localStorage.getItem(ORDER_LOADBY_LINENUMBER) === 'true' || false
  )
  const [designID,setDesignID] = useState(
    localStorage.getItem(DESIGN_ID_KEY) || ''
  )
  const [loadByDesignID,setLoadByDesignID] = useState(
    localStorage.getItem(ORDER_LOADBY_DESIGNID) === 'true' || false
  )
  const [isAddToSystem, setIsAddToSystem] = useState(
    localStorage.getItem(IS_ADD_TO_SYSTEM) === 'true' || false
  )
  const [orderCurrency, setOrderCurrency] = useState(
    localStorage.getItem(ORDER_CURRENCY_KEY) || ''
  );

  //session context, do not store in localStorage
  const [systemState, setSystemState] = useState({isLoading:false, isChanged:true, setSummaryLoaded:false, setSummaryLoadedOLSID: 0})

  //setLoadByLineNumber
  useEffect(() => {
    localStorage.setItem(LOCK_ORDER_KEY, JSON.stringify(lockOrder));
    localStorage.setItem(MASTER_KEY_COUNTER_KEY, masterKeyCounter);
    localStorage.setItem(LOCK_SET_COUNTER_KEY, lockSetCounter);
    localStorage.setItem(ORDER_ID_KEY, orderID);
    localStorage.setItem(ORDER_LOCK_SYSTEM_ID_KEY, orderLockSystemID);
    localStorage.setItem(LOCK_SYSTEM_ID_KEY, lockSystemID);
    localStorage.setItem(ORDER_LOCK_SYSTEM_STATUS_KEY, orderLockSystemStatus);
    localStorage.setItem(SYSTEM_BUCKET_TYPE_KEY, systemBucketType);
    localStorage.setItem(CCID_KEY, ccid);
    localStorage.setItem(CUSTOMER_KEY, JSON.stringify(customer));
    localStorage.setItem(SAP_ORDER_NUMBER_KEY, sapOrderNumber);
    localStorage.setItem(ORDER_PO_NUMBER_KEY, orderPONumber);
    localStorage.setItem(SAP_LINE_NUMBER_KEY, sapLineNumber);
    localStorage.setItem(NO_KEY_LOCK_SETS_KEY, JSON.stringify(noKeyLockSets));
    //localStorage.setItem(LOCK_SYSTEMS_KEY, JSON.stringify(lockSystems));
    localStorage.setItem(ORDER_LOCK_SYSTEMS_KEY, JSON.stringify(orderLockSystems));
    localStorage.setItem(ORDER_LOADBY_LINENUMBER, (loadByLineNumber ?? '').toString());
    localStorage.setItem(DESIGN_ID_KEY, (designID ?? '').toString());
    localStorage.setItem(ORDER_LOADBY_DESIGNID, (loadByDesignID ?? '').toString());
    localStorage.setItem(IS_ADD_TO_SYSTEM, (isAddToSystem ?? '').toString());
    localStorage.setItem(ORDER_CURRENCY_KEY, orderCurrency);
  }, [lockOrder, masterKeyCounter, lockSetCounter, orderID, orderLockSystemID, orderLockSystemStatus, lockSystemID, systemBucketType, ccid, customer,
    sapOrderNumber, orderPONumber, sapLineNumber, noKeyLockSets, orderLockSystems, loadByLineNumber, designID, loadByDesignID, isAddToSystem, orderCurrency]);
  return (
    <LockOrderContext.Provider displayName='LockOrderContext' value={{
      lockOrder, setLockOrder,
      masterKeyCounter, setMasterKeyCounter,
      lockSetCounter, setLockSetCounter,
      orderID, setOrderID,
      orderLockSystemID, setOrderLockSystemID,
      orderLockSystemStatus, setOrderLockSystemStatus,
      systemBucketType, setSystemBucketType,
      lockSystemID, setLockSystemID,
      ccid, setCcid,
      sapOrderNumber, setSapOrderNumber,
      orderPONumber, setOrderPONumber,
      sapLineNumber, setSapLineNumber,
      noKeyLockSets, setNoKeyLockSets,
      orderLockSystems, setOrderLockSystems,
      //lockSystems, setLockSystems,
      loadByLineNumber,setLoadByLineNumber,
      designID,setDesignID,
      loadByDesignID,setLoadByDesignID,
      customer, setCustomer,
      isAddToSystem, setIsAddToSystem,
      systemState, setSystemState,
      orderCurrency, setOrderCurrency,
    }}>
      {children}
    </LockOrderContext.Provider>
  );
};