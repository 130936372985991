import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'

export let reactPlugin: ReactPlugin = new ReactPlugin()

var appInsights = new ApplicationInsights({
  config: {
      connectionString: window.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING,
      enableAutoRouteTracking: true,
      extensions: [reactPlugin]
  }
})

appInsights.loadAppInsights()
export const getAppInsights = () => appInsights