import axios, { AxiosRequestConfig } from "axios";

/**
 * Base API URL to which to make requests.
 */
let API_BASE_URL: string = window.REACT_APP_API_BASE_URL;

axios.defaults.baseURL = API_BASE_URL

export enum ContentTypes {
  Json = 'application/json',
  Plaintext = 'text/plain',
  FormData = 'multipart/form-data',
  Octet = 'application/octet-stream'
}

/**
 * @deprecated Use getAxiosRequestConfig instead
 * @param bearerToken 
 * @param isJSON 
 * @returns 
 */
const getConfig = (bearerToken: string, isJSON: boolean = true): AxiosRequestConfig<any> => {
  return getAxiosRequestConfig(bearerToken, isJSON ? ContentTypes.Json : ContentTypes.Plaintext)
}

/**
 * 
 * @param bearerToken 
 * @param contentType 
 * @returns 
 */
const getAxiosRequestConfig = (bearerToken: string, contentType: ContentTypes = ContentTypes.Json): AxiosRequestConfig<any> => {
  return {
    headers: {
      'Authorization': `Bearer ${bearerToken}`,
      'Content-Type': contentType
    }
  }
}

/**
 * Builds the axios configuration for guest access.
 * @param _key 
 * @returns 
 */
const getGuestConfig = (_key: string): AxiosRequestConfig<any> => {
  return {
    headers: {
      'ViewAuthorization': _key,
      'Content-Type': 'application/json'
    }
  }
}

export { API_BASE_URL, getConfig, getGuestConfig, getAxiosRequestConfig }