export interface IHelpRequest {
  userEmail: string;
  summary: string;
  description: string;
  ccid: string;
  sapOrderNumber: string;
  designId: string;
  currentScreen: string;
}

export default class HelpRequest implements IHelpRequest {
  constructor(
    public userEmail: string,
    public summary: string,
    public description: string,
    public ccid: string,
    public sapOrderNumber: string,
    public designId: string,
    public currentScreen: string
  ) {}
}
